var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"service-section section-gap-full"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"single-service"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sign_up($event)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Full Name")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var valid = ref.valid;
var untouched = ref.untouched;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],staticClass:"form-control",class:{ invalid: invalid, valid: valid, untouched: untouched },attrs:{"type":"text","placeholder":"Full name"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email address")]),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var valid = ref.valid;
var untouched = ref.untouched;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:{ invalid: invalid, valid: valid, untouched: untouched },attrs:{"type":"email","placeholder":"Email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('ValidationProvider',{attrs:{"rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var valid = ref.valid;
var untouched = ref.untouched;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{ invalid: invalid, valid: valid, untouched: untouched },attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Confirm Password")]),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var valid = ref.valid;
var untouched = ref.untouched;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.confirm_password),expression:"user.confirm_password"}],staticClass:"form-control",class:{ invalid: invalid, valid: valid, untouched: untouched },attrs:{"type":"password","placeholder":"Confirm Password"},domProps:{"value":(_vm.user.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "confirm_password", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"btn btn-success btn-flat m-b-30 m-t-30",staticStyle:{"background":"#24b99a","color":"#fff"},attrs:{"type":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.button_text))]),_c('div',{staticClass:"register-link m-t-15 text-center mt-3"},[_c('p',[_vm._v(" Already have an account ? "),_c('router-link',{attrs:{"to":"/signin"}},[_vm._v("Sign in Here")])],1)])])]}}])})],1)]),(_vm.isLoading)?_c('Spinner'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-title",staticStyle:{"margin-bottom":"50px"}},[_c('h1',{staticClass:"text-center"},[_vm._v("Sign up")])])}]

export { render, staticRenderFns }